import "./saga";
export * from "./slice";
export * from "./helpers";

const filterProps = ["name", "email", "department_name", "roleName"]

const getUserState = (state) => state.user;

export const getLogInStatus = (state) => {
  let userState = getUserState(state);
  return userState.logInStatus
}
export const getActiveUsers = (state) => {
  let userState = getUserState(state);
  return userState.activeUsers;
}
export const getUserAvatorName = (user) => {
  let { firstname, lastname } = user;
  firstname = String(firstname).trim();
  lastname = String(lastname).trim();
  let firstChar = firstname[0];
  let secondChar = lastname.length === 0 ? firstname[1] : lastname[0];
  return firstChar + secondChar;
}

