import { put, select, takeEvery } from "redux-saga/effects";
import { SagaRegistry, requestHandler, axios, setAlertAndLoading, getUserDetails, sortByProperty, fetchS3ObjectAsBlob } from "../app";
import { commonActions } from "./slice";
import { saveAs } from 'file-saver';

function* fetchDepartments() {
  yield requestHandler(function* () {
    let res = yield axios.get('/consoles/department_list');
    res = (res.department_list || []).map((_) => ({ label: _, id: _ }));
    res.sort(sortByProperty.bind(null, 'label'))
    yield put(commonActions.setDepartments(res))
  }, "Failed to Fetch Departments", false);
}

function* fetchDocumentTypes() {
  yield requestHandler(function* () {
    let res = yield axios.get('/centrals/doc_type_list');
    res = Object.keys(res.types || {}).map((_) => ({ label: _, attribute: _, id: _ }));
    yield put(commonActions.setDocumentTypes(res))
  }, "Failed to Fetch Types", false);
}
function* fetchDocumentClassifications() {
  yield requestHandler(function* () {
    let res = yield axios.get('/centrals/doc_classi_list');
    let types = (res.types || {}), temp = Object.keys(types), list = [];
    for (let i = 0; i < temp.length; i++) {
      let cls = temp[i]
      list[types[cls]] = { label: cls, attribute: cls, id: cls }
    }
    yield put(commonActions.setDocumentClassifications(list))
  }, "Failed to Fetch Classification", false);
}
function* fetchDeptResponders() {
  yield requestHandler(function* () {
    let res = yield axios.get('/incidents/inc_dept_responders');
    let list = res.dept_responders ? res.dept_responders : res;
    list = Array.isArray(list) ? list : [];
    list = list.map((item) => {
      item.responder_name = '';
      getUserDetails(item, 'responder')
      return { ...item }
    });
    yield put(commonActions.setDeptResponders(list))
  }, "Failed to Fetch Department Responders", false);
}
function* updateDeptResponder({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(false);
    const { department, userId } = payload;
    const body = { incident_dept_responders: { department_name: department, responder_id: userId } }
    yield axios.post('/incidents/set_inc_dept_reponder', body);
    yield setAlertAndLoading(null, { message: "Reponder Updated Successfully." })
    yield put(commonActions.fetchDeptResponders())
  }, "Failed to Update Department Responder", false);
}
function* downloadAttachement({ payload }) {
  yield requestHandler(function* () {
    const { attachment } = payload;
    const blob = yield fetchS3ObjectAsBlob(attachment.encrypt_evidence_url);
    if (blob instanceof Blob) {
      saveAs(blob, attachment.document_file_name);
    }
    console.log("blob", attachment);
  }, "Failed to download attachement");
}
SagaRegistry.register(function* userSaga() {
  yield takeEvery("common/fetchDepartments", fetchDepartments);
  yield takeEvery("common/fetchDocumentTypes", fetchDocumentTypes);
  yield takeEvery("common/fetchDocumentClassifications", fetchDocumentClassifications);
  yield takeEvery("common/fetchDeptResponders", fetchDeptResponders);
  yield takeEvery("common/updateDeptResponder", updateDeptResponder);
  yield takeEvery("common/downloadAttachement", downloadAttachement);
})